import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RedirectionService {
  public refreshPage = new Subject<any>();
  public refreshTab = new Subject<any>();

  constructor() { }

  // // Observable for page refresh
  // // Set page url Coordinates
  // setRefreshMyEarningPage(data: any) {
  //   this.refreshPage.next(data);
  // }

  // // Get page url Coordinates
  // getRefreshMyEarningPage(): Subject<any> {
  //   return this.refreshPage;
  // }

  // Observable for page/tab redirect
  // Set page tab redirect
  setRefreshTabOrPage(data: any) {
    this.refreshTab.next(data);
  }

  // Get page tab redirect
  getRefreshTabOrPage(): Subject<any> {
    return this.refreshTab;
  }
}
