import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CommonProvider } from '../core/common';
import { Apiurl } from '../core/route';
import { ToastService } from '../core/services/toast.service';
import { DateTime } from 'luxon';
import { JobApplicationModalPage } from './job-application-modal/job-application-modal.page';
import { ModalController } from '@ionic/angular';
import { JobApplication } from '../core/modal/job-application.modal';
import { JobSeeker } from '../core/modal/job-seeker.modal';
import { LoadingService } from '../core/services/loading.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { Share } from '@capacitor/share';
import { Capacitor } from '@capacitor/core';
import { JobUtilervice } from '../core/util/job-util.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AvailableJobsService {

  jobLists: any[] = [];
  errorInApiCall: boolean = false;
  employmentId: string = null;
  jobApplicationId: string = null;
  selectedJobDetails: any = null;
  selectedJobPreferences: any = null;
  jobPref: any = null;
  jobSeeker: JobSeeker;

  pageSize = 10;
  page = 0;
  totalAvailableRecords = 0;
  loadedMyAvailablesRecords = 0;

  showNoJobFound: boolean = false;
  displayDeatilsAfterLoad: boolean = false;

  constructor(
    public commonProvider: CommonProvider,
    public router: Router,
    public toastService: ToastService,
    public modalCtrl: ModalController,
    public loadingService: LoadingService,
    public storage: Storage,
    public jobUtilService: JobUtilervice
  ) {
    this.storage.create();
    this.setInitialValues();
  }

  // Jobseeker data get from storage
  async setInitialValues() {
    const loginUserInfo = await this.storage.get('loginUserInfo');
    this.jobSeeker = loginUserInfo;
  }

  // Get all jobs list
  async getAllJobsList(search?) {
    this.showNoJobFound = false;
    const loginUserGender = await this.storage.get('loginUserGender') ? await this.storage.get('loginUserGender') : localStorage.getItem('loginUserGender');
    console.log(loginUserGender, await this.storage.get('loginUserGender'), localStorage.getItem('loginUserGender'))
    if (search) {
      this.jobLists = [];
      this.page = 0;
    }
    this.loadingService.show();
    let params = '?sort=createdOn,desc&page=' + this.page + '&size=' + this.pageSize;
    let param = { "gender": loginUserGender, "query": search }
    await this.commonProvider.PostMethod(Apiurl.GetJobsList + params, param).then((res: any) => {
      this.loadingService.dismiss();
      this.errorInApiCall = false;
      if (res && res.content?.length != 0) {
        this.totalAvailableRecords = res?.totalElements;
        this.loadedMyAvailablesRecords = res?.content?.length;
        res.content?.forEach((element) => {
          this.jobLists.push(element);
        });
        if (this.jobLists.length == 0) {
          this.showNoJobFound = true;
        } else {
          this.showNoJobFound = false;
        }
      } else {
        this.showNoJobFound = true;
      }
    }).catch((err: HttpErrorResponse) => {
      this.loadingService.dismiss();
      this.jobLists = [];
      this.page = 0;
      this.errorInApiCall = true;
      this.showNoJobFound = false;
      console.log(err);
    })
  }

  // Get job by job id and login user id
  async getSelectedJobById(employmentId?, addChampInReference?: boolean) {
    const loginUserId = await this.storage.get('loginUserId');
    const loginUserGender = await this.storage.get('loginUserGender');
    this.employmentId = employmentId;
    this.selectedJobDetails = null;
    this.jobApplicationId = null;
    this.displayDeatilsAfterLoad = false;

    this.loadingService.show();
    if (this.employmentId && loginUserId) {
      let params = this.employmentId + '/' + loginUserId;
      let param = { "gender": loginUserGender }
      this.commonProvider.GetMethod(Apiurl.GetJobByJobId + params, param).then(async (res: any) => {
        this.loadingService.dismiss();
        if (res) {
          this.selectedJobDetails = res;
          console.log("selectedJobDetails------------", this.selectedJobDetails)
          this.jobApplicationId = this.selectedJobDetails?.jobApplicationId;
          this.selectedJobDetails?.dates?.forEach(date => {
            this.jobUtilService.shiftDayChange(date.date, date.timeFrom, date.timeTo);
          })
          if (addChampInReference) {
            if (!this.selectedJobDetails?.jobApplicationId || !this.selectedJobDetails?.jobFulfilmentId) {
              this.addChampToShift();
            } else {
              this.displayDeatilsAfterLoad = true;
            }
          } else {
            this.displayDeatilsAfterLoad = true;
          }
        }
      }).catch((err: HttpErrorResponse) => {
        this.loadingService.dismiss();
        console.log(err);
      })
    } else {
      // this.router.navigateByUrl("tabs/available-jobs/available-jobs-list")
      this.loadingService.dismiss();
      this.toastService.showMessage("Employment Id not found!")
    }
  }

  // Get job by job id and login user id globally
  async getSelectedJobByIdGlobally() {
    // const loginUserId = await this.storage.get('loginUserId');
    // const loginUserGender = await this.storage.get('loginUserGender');
    this.employmentId = this.router.url.split('available-job-details-global/')[1];
    this.selectedJobDetails = null;
    this.jobApplicationId = null;

    this.loadingService.show();
    if (this.employmentId) {
      let params = this.employmentId;
      this.commonProvider.GetMethod(Apiurl.GetJobDetailsGlobally + params, null).then((res: any) => {
        this.loadingService.dismiss();
        if (res) {
          this.selectedJobDetails = res;
          console.log("selectedJobDetails===", this.selectedJobDetails)
          this.jobApplicationId = this.selectedJobDetails?.jobApplicationId;
          this.selectedJobDetails?.dates?.forEach(date => {
            this.jobUtilService.shiftDayChange(date.date, date.timeFrom, date.timeTo);
          })
        }
      }).catch((err: HttpErrorResponse) => {
        this.loadingService.dismiss();
        console.log(err);
      })
    } else {
      // this.router.navigateByUrl("tabs/available-jobs/available-jobs-list")
      this.loadingService.dismiss();
      this.toastService.showMessage("Employment Id not found!")
    }
  }


  // Apply for selected job
  async JobPreference(isDetailPage?: boolean, obj?: any, fixedTotal?: any) {
    const loginUserId = await this.storage.get('loginUserId');
    // this.loadingService.show();
    let params = '?page=0&size=1&sort=createdOn,desc&jobSeekerId=' + loginUserId;
    this.commonProvider.GetMethod(Apiurl.JobPreference + params, null).then(async (res: any) => {
      // this.loadingService.dismiss();
      let typeIdFound = false;
      console.log("%%%%%%%%%", res)
      if (res) {
        this.selectedJobPreferences = res;
        if (isDetailPage) {
          this.logicForValidToApplyJob(res, typeIdFound, obj, fixedTotal);
        }
      }
    }).catch((err: HttpErrorResponse) => {
      // this.loadingService.dismiss();
      console.log(err);
    })
  }

  // Login user eligible for apply job or not that logic set here
  async logicForValidToApplyJob(res, typeIdFound, obj, fixedTotal?: any) {
    // this.loadingService.show();
    console.log(this.selectedJobDetails)
    if (res && res.content.length != 0 && res.content[0]?.jobTypePreferences?.length != 0) {
      // this.loadingService.dismiss();
      for (let pref of res?.content[0]?.jobTypePreferences) {
        // if (pref.typeId == this.selectedJobDetails?.jobTypeId) {
        typeIdFound = true;
        if (this.selectedJobDetails?.jobSeekerPaymentInfo?.level == 'Expert') {
          if (pref.level == 'Expert') {
            this.checkForHourlyAndFixedShift(obj, fixedTotal);
            return;
          }
          else {
            this.toastService.showMessage('You can not apply to this job. Your level is not suitable for this job type.');
            return;
          }
        }
        else if (this.selectedJobDetails?.jobSeekerPaymentInfo?.level == 'Intermediate') {
          if (pref.level == 'Intermediate' || pref.level == 'Expert') {
            this.checkForHourlyAndFixedShift(obj, fixedTotal);
            return;
          }
          else {
            this.toastService.showMessage('You can not apply to this job. Your level is not suitable for this job type.');
            return;
          }
        }
        else if (this.selectedJobDetails?.jobSeekerPaymentInfo?.level == 'Beginner') {
          this.checkForHourlyAndFixedShift(obj, fixedTotal);
          return;
        }
        else {
          this.toastService.showMessage('Something went wrong, try again later.');
          return;
        }
        // }
      }
    } else {
      // this.loadingService.dismiss();
      if (this.selectedJobDetails?.jobSeekerPaymentInfo?.level == 'Beginner') {
        this.checkForHourlyAndFixedShift(obj, fixedTotal);
        return;
      }
    }
    // if (!typeIdFound) {
    //   await this.toastService.showMessage('You can not apply to this job, your profile not approved for this Job Type');
    // }
  }

  // Go to set hourly rate page
  async checkForHourlyAndFixedShift(obj?: any, fixedTotal?: any) {
    console.log("$$$$$$$$$$$$$", obj)
    const isDateMatched = await this.betweenTimeCheck();
    if (!isDateMatched) {
      this.changeApplicationStatus(obj, fixedTotal);
    } else {
      this.toastService.showMessage('You can not apply to this job. You already applied to another job for same date and time.');
      return;
    }
  }

  // Logic for start and endtime check 
  async betweenTimeCheck(): Promise<boolean> {
    this.loadingService.show();
    const loginUserId = await this.storage.get('loginUserId');
    let isFound = false;
    const jobDates = this.selectedJobDetails?.dates.map(date => ({
      timeFrom: DateTime.local(date?.date[0], date?.date[1], date?.date[2], date?.timeFrom[0], date?.timeFrom[1]),
      timeTo: DateTime.local(date?.date[0], date?.date[1], date?.date[2], date?.timeTo[0], date?.timeTo[1])
    }));
    let params = loginUserId + '?sort=createdOn,desc&page=0&size=10000&status=APPROVED'
    this.commonProvider.GetMethod(Apiurl.GetMyJobs + params, null).then(async (res: any) => {
      this.loadingService.dismiss();
      await res?.content?.forEach(job => {
        job.dates.forEach(date => {
          const jobDateStart = DateTime.local(date?.date[0], date?.date[1], date?.date[2], date?.timeFrom[0], date?.timeFrom[1]);
          const jobDateEnd = DateTime.local(date?.date[0], date?.date[1], date?.date[2], date?.timeTo[0], date?.timeTo[1]);
          jobDates.forEach(jobDates => {
            if ((jobDateStart >= jobDates.timeFrom && jobDateStart <= jobDates.timeTo) || (jobDateEnd >= jobDates.timeFrom && jobDateEnd <= jobDates.timeTo)) {
              return isFound = true;
            }
          });
        });
      });

    }).catch((err: HttpErrorResponse) => {
      this.loadingService.dismiss();
      console.log(err);
    })
    return isFound;
  }

  // update hourly rates
  async updateHourlyRateRange(hourlyRate, isApplyLater, status = 'PENDING', estimatedIncome?: any) {
    console.log("@@@@@@@@@", estimatedIncome, status)
    // this.loadingService.show();
    if (this.jobPref?.jobTypePreferences?.length == 0) {
      this.loadingService.dismiss();
      let jobTypePreferencesRequests = [];
      let data = {
        "typeId": this.selectedJobDetails?.jobTypeId,
        "typeName": this.selectedJobDetails?.jobTypeName,
      }
      jobTypePreferencesRequests.push(data);
      let params = {
        "jobSeekerId": await this.storage.get('loginUserId'),
        "jobTypeRequests": jobTypePreferencesRequests
      }
      this.commonProvider.PutMethod(Apiurl.JobPreference + '/' + this.jobPref.id, params).then(async (res: any) => {
        // this.loadingService.dismiss();
        await this.submitJobApplication(hourlyRate, isApplyLater, status, estimatedIncome);
      }).catch((err: HttpErrorResponse) => {
        // this.loadingService.dismiss();
        console.log(err);
      })
    } else {
      let jobTypePreferencesRequests = [];

      this.jobPref?.jobTypePreferences?.forEach(element => {
        let data = {
          "typeId": element?.typeId,
          "typeName": element?.typeName,
        }
        jobTypePreferencesRequests.push(data);
      })

      let JobTypeNotMatched = false;

      this.jobPref?.jobTypePreferences?.forEach(async (element) => {
        if (element.typeId != this.selectedJobDetails?.jobTypeId) {
          JobTypeNotMatched = true;
        } else {
          JobTypeNotMatched = false;
        }
      });

      if (JobTypeNotMatched) {
        this.saveJobPreferences(jobTypePreferencesRequests, hourlyRate, isApplyLater, status, estimatedIncome);
      } else {
        if (this.jobPref && this.jobPref.id) {
          this.commonProvider.PostMethod(Apiurl.UpdateHourlyRate + this.jobPref.id, { jobTypeHourlyRateRequests: this.jobPref?.jobTypePreferences }).then((res: any) => {
            // this.loadingService.dismiss();
            if (res) {
              this.submitJobApplication(hourlyRate, isApplyLater, status, estimatedIncome);
            }
          }).catch((err: HttpErrorResponse) => {
            // this.loadingService.dismiss();
            console.log(err);
          })
        } else {
          this.submitJobApplication(hourlyRate, isApplyLater, status, estimatedIncome);
        }
      }
    }
  }

  async saveJobPreferences(jobTypePreferencesRequests, hourlyRate, isApplyLater, status, estimatedIncome?: any) {
    let data = {
      "typeId": this.selectedJobDetails?.jobTypeId,
      "typeName": this.selectedJobDetails?.jobTypeName,
    }
    jobTypePreferencesRequests.push(data);
    let params = {
      "jobSeekerId": await this.storage.get('loginUserId'),
      "jobTypeRequests": jobTypePreferencesRequests
    }
    this.commonProvider.PutMethod(Apiurl.JobPreference + '/' + this.jobPref.id, params).then(async (res: any) => {
      this.loadingService.dismiss();
      this.commonProvider.PostMethod(Apiurl.UpdateHourlyRate + this.jobPref.id, { jobTypeHourlyRateRequests: this.jobPref?.jobTypePreferences }).then((res: any) => {
        this.submitJobApplication(hourlyRate, isApplyLater, status, estimatedIncome);
      }).catch((err: HttpErrorResponse) => {
        console.log(err);
      })
    }).catch((err: HttpErrorResponse) => {
      this.loadingService.dismiss();
      console.log(err);
    })
  }

  // Apply for job & submit job application
  async submitJobApplication(hourlyRate, isApplyLater, status = 'PENDING', estimatedIncome?: any) {
    console.log("estimatedIncome------", estimatedIncome, status)
    const loginUserId = await this.storage.get('loginUserId');
    const loginUserGender = await this.storage.get('loginUserGender');
    this.loadingService.show();
    let application: JobApplication = new JobApplication();
    application.employmentId = this.employmentId
    application.jobSeekerId = loginUserId;
    application.isApplyLater = isApplyLater;
    application.hourlyRate = hourlyRate || 0;
    application.gender = loginUserGender;
    this.selectedJobDetails.estimatedIncome = estimatedIncome;

    console.log("this.selectedJobDetails=====", this.selectedJobDetails, status)
    if (application.hourlyRate != 0) {
      if (!this.selectedJobDetails?.jobApplicationId) {
        this.commonProvider.PostMethod(Apiurl.SubmitJobApplication, application).then(async (res: any) => {
          this.loadingService.dismiss();
          if (!isApplyLater) {
            if (res && res.id) {
              let obj = {
                title: "Job application successful",
                message: "Congratulations, Your job application is now submitted and awaiting for approval. we will inform you as soon as it is approved.",
                okBtnText: "Apply for another Job",
                cancelBtnText: "Close",
                img: "../../assets/imgs/job-application-successful.svg",
                success: true,
                selectedJobDetails: this.selectedJobDetails
              }
              const modal = await this.modalCtrl.create({
                component: JobApplicationModalPage,
                componentProps: obj
              });
              await modal.present();
            } else {
              let obj = {
                title: "Job application failed",
                message: "Your job application failed.Please try again later. If this continues to be a problem, please reach out to customer support.",
                okBtnText: "Try again",
                cancelBtnText: "Close",
                img: "../../assets/imgs/job-application-failed.svg",
                hourlyRate: hourlyRate,
                isApplyLater: isApplyLater,
                success: false
              }
              const modal = await this.modalCtrl.create({
                component: JobApplicationModalPage,
                componentProps: obj
              });
              await modal.present();
            }
          } else {
            await this.toastService.showMessage('You saved this job for later use!');
            await this.getSelectedJobById();
          }

        }).catch((err: HttpErrorResponse) => {
          this.loadingService.dismiss();
          console.log(err);
        })
      } else {
        console.log("status", status)
        application.status = status;
        this.commonProvider.PutMethod(Apiurl.SubmitJobApplication + '/' + this.selectedJobDetails?.jobApplicationId, application).then(async (res: any) => {
          console.log(res)
          this.loadingService.dismiss();
          if (res && res.status == 'success') {
            let obj = {
              title: "Shift invite accepted",
              message: "Thank you for accepting the invite for" + (this.selectedJobDetails?.employmentTitle ? this.selectedJobDetails?.employmentTitle : this.selectedJobDetails?.title) + ". We will notify you as soon as there is a decision on your selection for the shift.",
              okBtnText: "Apply for another Job",
              cancelBtnText: "Close",
              img: "../../assets/imgs/job-application-successful.svg",
              success: true,
              selectedJobDetails: this.selectedJobDetails
            }
            const modal = await this.modalCtrl.create({
              component: JobApplicationModalPage,
              componentProps: obj
            });
            await modal.present();
          } else {
            let obj = {
              title: "Shift invite failed",
              message: "Your job application failed.Please try again later. If this continues to be a problem, please reach out to customer support.",
              okBtnText: "Try again",
              cancelBtnText: "Close",
              img: "../../assets/imgs/job-application-failed.svg",
              hourlyRate: hourlyRate,
              isApplyLater: isApplyLater,
              success: false
            }
            const modal = await this.modalCtrl.create({
              component: JobApplicationModalPage,
              componentProps: obj
            });
            await modal.present();
          }
        }).catch((err: HttpErrorResponse) => {
          this.loadingService.dismiss();
          console.log(err);
        })
      }
    } else {
      this.loadingService.dismiss();
      await this.toastService.showMessage('Please select valid hourly rate!');
    }
  }

  //Remove Bookmarked
  removeBookMark() {
    if (this.jobApplicationId) {
      this.commonProvider.PutMethod(Apiurl.RemoveBookMark + this.jobApplicationId, null).then((res: any) => {
        this.toastService.showMessage('You removed this job from saved!');
        this.getSelectedJobById();
      }).catch((err: HttpErrorResponse) => {
        console.log(err);
      })
    } else {
    }
  }

  // Share job details to Whatsapp
  async shareSelectedJobDetails() {
    let url = this.router.url
    if (url.includes('available-job-details-global')) {
    } else {
      if (url.includes('available-job-details')) {
        url = this.router.url.replace('available-job-details', 'available-job-details-global');
      }
    }
    // let pageUrl = encodeURIComponent(url);
    let jobType = encodeURIComponent(this.selectedJobDetails?.employmentTitle ? this.selectedJobDetails?.employmentTitle : this.selectedJobDetails?.title);
    // send message in App whatsapp
    // window.open("https://api.whatsapp.com/send/?text=Hey!%20I%20found%20a%20" + jobType + "%20job%20on%20hour4U.%20check%20it%20out%20here%20%F0%9F%91%87%0A%20https%3A%2F%2Fuatapp.hour4u.com" + pageUrl);

    // send message in Web whatsapp
    // if (Capacitor.getPlatform() == 'web') {
    //   let webUrl = 'https%3A%2F%2Fuatapp.hour4u.com/#';
    //   if (environment.apiUrl == 'https://uatapi.hour4u.com/api/') { // UAT 
    //     webUrl = 'https%3A%2F%2Fuatapp.hour4u.com/#';
    //   }
    //   if (environment.apiUrl == 'https://api.hour4u.com/api/') { // PROD
    //     webUrl = 'https%3A%2F%2Fapp.hour4u.com/#';
    //   }
    //   // window.open("https://web.whatsapp.com/send/?text=Hey!%20I%20found%20a%20" + jobType + "%20job%20on%20hour4U.%20check%20it%20out%20here%20%F0%9F%91%87%0A%20" + webUrl + pageUrl);
    // }

    // send message in social share
    // if (Capacitor.getPlatform() !== 'web') {
    let webUrl = 'https://uatapp.hour4u.com/#';
    if (environment.apiUrl == 'https://uatapi.hour4u.com/api/') { // UAT 
      webUrl = 'https://uatapp.hour4u.com/#';
    }
    if (environment.apiUrl == 'https://api.hour4u.com/api/') { // PROD
      webUrl = 'https://app.hour4u.com/#';
    }

    const title = 'Hey! I found ' + (this.selectedJobDetails?.employmentTitle ? this.selectedJobDetails?.employmentTitle : this.selectedJobDetails?.title) + ' job on Hour4U. check it out here 👇\n'
    await Share.share({
      title: jobType,
      text: title,
      url: webUrl + url,
      dialogTitle: 'hour4u.com',
    }).then(res => {
      console.log(res)
    }).catch(err => {
      console.log(err);
    });
    // }
  }

  // Change application status
  changeApplicationStatus(obj, fixedTotal?: any) {
    console.log("@#@#@#@#", obj)
    if (obj) {
      let dataObj = Object.assign({}, obj);
      if (dataObj?.fulfilmentId) {
        if (dataObj.updatedStatus == "APPROVED") {
          dataObj.updatedStatus = 'ACCEPTED'
        }
        console.log(dataObj, obj)
        this.commonProvider.PutMethod(Apiurl.ChangeApplicationStatus, dataObj).then(async (res: any) => {
          // this.toastService.showMessage('Your application is ' + dataObj.updatedStatus);
          if (obj.updatedStatus == 'APPROVED') {
            if (this.selectedJobDetails?.jobSeekerPaymentInfo?.biddingAllowed) {
              this.router.navigateByUrl("set-hourly-rate")
            } else {
              this.approveShiftWithFixedHourlyRate(fixedTotal);
            }
          }
          else {
            let obj = {
              title: "Shift invite declined",
              message: "We are sorry that you are not able to take this shift. Yet, there are plenty of opportunities to look for. We will be looking forward to have you with us in the future.",
              okBtnText: "Apply for another Job",
              cancelBtnText: "Close",
              img: "../../assets/imgs/job-application-failed.svg",
              success: false,
            }
            const modal = await this.modalCtrl.create({
              component: JobApplicationModalPage,
              componentProps: obj
            });
            await modal.present();
          }
        }).catch((err: HttpErrorResponse) => {
          console.log(err);
        })
      } else {
        this.approveShiftWithFixedHourlyRate(fixedTotal);
      }
    } else {
      this.router.navigateByUrl("set-hourly-rate")
    }
  }

  // approve Shift With Fixed Hourly Rate
  approveShiftWithFixedHourlyRate(fixedTotal?: any) {
    if (this.jobPref) {
      this.jobPref.jobTypePreferences.forEach(async prefType => {
        prefType.maxHourlyRate = prefType.maxHourlyRate ? prefType.maxHourlyRate : 1;
        prefType.level = prefType.level ? prefType.level : "Intermediate";

        if (this.selectedJobDetails?.jobTypeId == prefType.typeId) {
          prefType.maxHourlyRate = this.selectedJobDetails?.jobSeekerPaymentInfo?.fixedRate ? this.selectedJobDetails?.jobSeekerPaymentInfo?.fixedRate : 1;
          prefType.level = this.selectedJobDetails?.jobSeekerPaymentInfo?.level ? this.selectedJobDetails?.jobSeekerPaymentInfo?.level : "";
        }
      })
    }
    this.updateHourlyRateRange(this.selectedJobDetails?.jobSeekerPaymentInfo?.fixedRate, false, 'APPROVED', fixedTotal);
  }

  // add Champ To Shift
  async addChampToShift() {
    const loginUserInfo = await this.storage.get('loginUserInfo');
    let jobSeeker = JSON.parse(loginUserInfo);
    console.log(jobSeeker)
    let jobFulfilment = [];
    let jobSeekerObj = {
      jobSeekerId: jobSeeker.id,
      jobSeeker: jobSeeker,
      jobSeekerPaymentInfo: this.selectedJobDetails.jobSeekerPaymentInfo
    }
    jobFulfilment.push(jobSeekerObj);

    let obj = {
      "employmentId": this.selectedJobDetails.id,
      "fulfillerType": this.router.url.split('/')[4],
      "fulfilerId": this.router.url.split('/')[3],
      "jobFulfilment": jobFulfilment
    }
    // this.loadingService.show();
    console.log(obj)
    this.commonProvider.PostMethod(Apiurl.ChangeApplicationStatus, obj).then(async (res: any) => {
      // this.loadingService.dismiss();
      if (res) {
        this.getSelectedJobById(this.router.url.split('/')[2]);
      }
    }).catch(err => {
      // this.loadingService.dismiss();
    })
  }
}
