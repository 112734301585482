import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// import { AppUpdate } from '@ionic-native/app-update/ngx';
import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update';

@Injectable({
    providedIn: 'root'
})
export class AppUpdateService {
    // updateUrl = 'https://uat.hour4u.com/appVersion/version.xml';
    forDeepLink: boolean = false;
    showListPage: boolean = false;
    openLinkFromDeeplink: boolean = false;
    availableJobLink: string = null;

    currentVersion: any;
    availableVersion: any;

    constructor(
        public router: Router
        // public appUpdate: AppUpdate,
    ) {
        // this.checkAppUpdate();
    }

    async checkAppUpdate() {

        const getCurrentAppVersion = async () => {
            const result = await AppUpdate.getAppUpdateInfo();
            console.log("(((((((((((((((((((", result)
            this.currentVersion = result.currentVersion;
            return result.currentVersion;
        };
        getCurrentAppVersion();

        const getAvailableAppVersion = async () => {
            const result = await AppUpdate.getAppUpdateInfo();
            console.log("%$%$#%$%#$%$#%#$%$#%#$", result)
            this.availableVersion = result.availableVersion;
            return result.availableVersion;
        };
        getAvailableAppVersion();

        if (this.currentVersion != this.availableVersion) {
            const performImmediateUpdate = async () => {
                const result = await AppUpdate.getAppUpdateInfo();
                console.log("@@@@@@@@@@@@@@@", result)
                if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
                    return;
                }
                if (result.immediateUpdateAllowed) {
                    this.router.navigateByUrl('app-update');
                    // await AppUpdate.performImmediateUpdate();
                }
            };
            performImmediateUpdate();
        }

        // const openAppStore = async () => {
        //     await AppUpdate.openAppStore();
        // };
        // openAppStore();


        // performImmediateUpdate();

        // const startFlexibleUpdate = async () => {
        //     const result = await AppUpdate.getAppUpdateInfo();
        //     console.log("##############", result)
        //     if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
        //         return;
        //     }
        //     if (result.flexibleUpdateAllowed) {
        //         await AppUpdate.startFlexibleUpdate();
        //     }
        // };

        // startFlexibleUpdate();

        // const completeFlexibleUpdate = async () => {
        //     await AppUpdate.completeFlexibleUpdate();
        // };

        // completeFlexibleUpdate();

        return;

        //     this.appUpdate.checkAppUpdate(this.updateUrl).then((update: any) => {
        //         console.log('update------', update);
        //     }).catch((error: any) => {
        //         console.log('error----------', error);
        //     });


        // const getCurrentAppVersion = async () => {
        // const result = await AppUpdate.getAppUpdateInfo();
        // console.log("AppUpdate=========", result)

        AppUpdate.getAppUpdateInfo().then((res: any) => {
            console.log("AppUpdate----", res)
            if (res.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
                alert("Update not available")
                return;
            }
            if (res.immediateUpdateAllowed) {
                alert("Update available")
                this.router.navigateByUrl('app-update');
                // AppUpdate.openAppStore({ country: 'IN' });
                // AppUpdate.startFlexibleUpdate().then(res => {
                //     console.log("++++++++res---", res)
                //     AppUpdate.completeFlexibleUpdate().then(data => {
                //         console.log("calllllll------", data)
                //     }).catch(err => {
                //         console.log("callerr---", err)
                //     });
                // }).catch(err => {
                //     console.log("err--=---", err)
                // })
            }
            // if (res.immediateUpdateAllowed) {
            //     AppUpdate.performImmediateUpdate().then(res => {
            //         console.log("++++++++res", res)
            //     }).catch(err => {
            //         console.log("err--", err)
            //     })
            // }
        }).catch(err => {
            console.log("AppUpdate Err===", err)
        })
    }


    updateApp() {
        AppUpdate.performImmediateUpdate().then(res => {
            console.log("performImmediateUpdate---", res)
            if (res.code == 5) {
                this.checkAppUpdate();
            }
        }).catch(err => {
            console.log("performImmediateUpdate err---", err)
        });
    }
}
